import { createGlobalStyle } from 'styled-components';

import { formatTypographyToFont } from 'helpers/typography';

import { widgetAppearanceAnimation } from 'components/globalStyles/animation';

import styled from 'styled-components/macro';
import { Theme } from 'themes/contracts';

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
          url('/fonts/light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
  font-family: ${({ theme }) => theme.fontFamilyCyrillic};
  src: local(${({ theme }) => theme.fontFamily}),
  url('/fonts/lightCyr.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
    url('/fonts/regular.woff2') format('woff2');

  font-weight: 400;
    font-style: normal;
}
@font-face {
  font-family: ${({ theme }) => theme.fontFamilyCyrillic};
  src: local(${({ theme }) => theme.fontFamily}),
  url('/fonts/regularCyr.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
    url('/fonts/medium.woff2') format('woff2');

  font-weight: 500;
    font-style: normal;
}
@font-face {
  font-family: ${({ theme }) => theme.fontFamilyCyrillic};
  src: local(${({ theme }) => theme.fontFamily}),
  url('/fonts/mediumCyr.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: ${({ theme }) => theme.fontFamily};
  src: local(${({ theme }) => theme.fontFamily}),
  url('/fonts/bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: ${({ theme }) => theme.fontFamilyCyrillic};
  src: local(${({ theme }) => theme.fontFamily}),
  url('/fonts/boldCyr.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
    url('/fonts/bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: ${({ theme }) => theme.fontFamilyCyrillic};
  src: local(${({ theme }) => theme.fontFamily}),
  url('/fonts/boldCyr.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overscroll-behavior: none;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
section,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  font: 400 16px / 18px ${({ theme }) => theme.mainFont};
}

img {
  max-width: 100%;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  font: ${({ theme }) =>
    formatTypographyToFont(theme.typography['label-small'])};
  color: ${({ theme }) => theme.colors.button.text.tertiaryLabel02};
}

body {
  position: relative;
  //min-width: 375px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.mainPage};
  overscroll-behavior-y: none;
}
button {
  font-family: ${({ theme }) => theme.mainFont};
  }

svg {
    flex: 0 0 auto;
  }
article{
  min-height: 100vh;
  background: ${({ theme }) => theme.background.main && theme.background.main};
  background-size: cover;
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    background: ${({ theme }) =>
      theme.background.mobile && theme.background.mobile};
  }
}
main{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  & > section {
    width: 100%;
    display: flex;
    flex: 1;
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      max-height: 100%;
      width: 100%;
      padding: 16px 14px 0;
      margin-bottom: 62px;
    }
  }
}

.container {
  max-width: 1180px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    padding: 0 16px;
  }
}

.table-wrapper {
  display: flex;
  flex: 1 1 100%;
}

.hide{
  display: none;
}
//TODO: need refactoring
#jivo_chat_widget {
  display: none;
}

#jivo_custom_widget {
  cursor: pointer;
  position: fixed;
  background: ${({ theme }) => theme.jivoMobile};
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 19px 38px 0 rgba(34, 36, 43, 0.3);
  width: 65px;
  height: 65px;
  bottom: 65px;
  right: 20px;
  z-index: 3;
  transition: all 0.3s ease;
  animation: 0.3s linear ${widgetAppearanceAnimation};
}
[class^='logoIconCloud'] {
   @media (max-width: ${({ theme }) => theme.mobileSize}) {
      display: none !important;
   }
}

#jivo_custom_widget:hover {
  right: 0;
}

#jvlabelWrap,
.button_1O {
  display: none !important;
}

.grecaptcha-badge{
  visibility: hidden;
}
`;

export const ScContent = styled.div`
  display: flex;
  max-width: 1052px;
  width: 100%;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    flex: 1;
  }
`;
